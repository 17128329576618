import { Route } from 'react-router-dom';

import { AdminPage } from './admin-page/AdminPage';
import { useUserState } from './auth/authenticate';
import getFlagsConfig from './config/getFlagsConfig';
import CLock from './lock-status/c-lock/CLock';
import JLock from './lock-status/j-lock/JLock';
import { LockStatusErrorPage } from './lock-status/lock-status-error-page/LockStatusErrorPage';
import { WLock } from './lock-status/w-lock/WLock';
import { YLock } from './lock-status/y-lock/YLock';
import PageNotFound from './page-not-found/PageNotFound';
import HowPaymentAgreementWorksPage from './payment-agreement/HowPaymentAgreementWorksPage';
import PaymentAgreementEnrollmentPage from './payment-agreement/PaymentAgreementEnrollmentPage';
import PaymentAgreementErrorPage from './payment-agreement/PaymentAgreementErrorPage';
import PaymentAgreementPage from './payment-agreement/PaymentAgreementPage';
import PaymentCenterTestPage from './payment-center-test/PaymentCenterTestPage';
import PaymentOffersErrorPage from './payment-offers/PaymentOffersErrorPage';
import PaymentOffersPage from './payment-offers/PaymentOffersPage';
import { ReconnectConfirmationPage as ReconnectConfirmationPageV2 } from './reconnect-service/reconnect-confirmation';
import { ReconnectServicePage as ReconnectServicePageV2 } from './reconnect-service/reconnect-service-options';
import ReconnectConfirmationPage from './reconnect-service/ReconnectConfirmationPage';
import { ReconnectErrorPage } from './reconnect-service/ReconnectErrorPage';
import ReconnectProbatePage from './reconnect-service/ReconnectProbatePage';
import ReconnectServicePage from './reconnect-service/ReconnectServicePage';
import { routes } from './constants';
import {
  PaymentExtensionConfirmDetailsPage,
  PaymentExtensionConfirmDetailsV2Page,
  PaymentExtensionEligiblePage,
  PaymentExtensionEligibleV2Page,
  PaymentExtensionErrorPage,
  PaymentExtensionScheduledPage,
  PaymentExtensionScheduledV2Page,
} from './payment-extension';

const {
  enableAdminDashboard,
  enablePaymentAgreement,
  enablePaymentCenterTestPage,
  enablePaymentExtensionDetailsApi,
  enableRestoreDetailsApi,
} = getFlagsConfig();

interface ProtectedProps {
  element: JSX.Element;
}
const Authenticated = ({ element }: ProtectedProps): JSX.Element => {
  useUserState(); // triggers gigya token retrieval
  return element;
};

const protectedRoute = (route: string, element: JSX.Element): JSX.Element => (
  <Route key={route} path={route} element={<Authenticated element={element} />} />
);

const publicRoute = (route: string, element: JSX.Element): JSX.Element => (
  <Route key={route} path={route} element={element} />
);

export const appRoutes: JSX.Element[] = [
  protectedRoute(routes.reconnect.probate, <ReconnectProbatePage />),
  protectedRoute(routes.error.reconnect, <ReconnectErrorPage />),

  protectedRoute(routes.error.paymentExtension, <PaymentExtensionErrorPage />),

  protectedRoute(routes.lockStatus.j, <JLock />),
  protectedRoute(routes.lockStatus.c, <CLock />),
  protectedRoute(routes.lockStatus.y, <YLock />),
  protectedRoute(routes.lockStatus.w, <WLock />),
  protectedRoute(routes.error.lockStatus, <LockStatusErrorPage />),

  protectedRoute('*', <PageNotFound />),
];

if (enableRestoreDetailsApi) {
  appRoutes.push(protectedRoute(routes.reconnect.confirmation, <ReconnectConfirmationPageV2 />));
  appRoutes.push(protectedRoute(routes.reconnect.paymentOptions, <ReconnectServicePageV2 />));
} else {
  appRoutes.push(protectedRoute(routes.reconnect.confirmation, <ReconnectConfirmationPage />));
  appRoutes.push(protectedRoute(routes.reconnect.paymentOptions, <ReconnectServicePage />));
}

if (enablePaymentExtensionDetailsApi) {
  appRoutes.push(protectedRoute(routes.paymentExtension.confirmationDetails, <PaymentExtensionConfirmDetailsV2Page />));
  appRoutes.push(protectedRoute(routes.paymentExtension.eligible, <PaymentExtensionEligibleV2Page />));
  appRoutes.push(protectedRoute(routes.paymentExtension.scheduled, <PaymentExtensionScheduledV2Page />));
} else {
  appRoutes.push(protectedRoute(routes.paymentExtension.confirmationDetails, <PaymentExtensionConfirmDetailsPage />));
  appRoutes.push(protectedRoute(routes.paymentExtension.eligible, <PaymentExtensionEligiblePage />));
  appRoutes.push(protectedRoute(routes.paymentExtension.scheduled, <PaymentExtensionScheduledPage />));
}

if (enableAdminDashboard) {
  appRoutes.push(publicRoute(routes.admin, <AdminPage />));
}

if (enablePaymentAgreement) {
  appRoutes.push(protectedRoute(routes.paymentAgreement.howPAWorks, <HowPaymentAgreementWorksPage />));
  appRoutes.push(protectedRoute(routes.paymentAgreement.enrollment, <PaymentAgreementEnrollmentPage />));
  appRoutes.push(protectedRoute(routes.error.paymentAgreement, <PaymentAgreementErrorPage />));
  appRoutes.push(protectedRoute(routes.paymentAgreement.enrolled, <PaymentAgreementPage />));
  appRoutes.push(protectedRoute(routes.error.paymentOffers, <PaymentOffersErrorPage />));
  appRoutes.push(protectedRoute(routes.paymentOffers.default, <PaymentOffersPage />));
}

if (enablePaymentCenterTestPage) {
  appRoutes.push(protectedRoute(routes.paymentCenterTest, <PaymentCenterTestPage />));
}
