import { useEffect } from 'react';

import { DteHeader } from '../../component-library/header/DteHeader';
import { serviceProtection } from '../../component-library/header/header';
import { DteStyledLogo } from '../../component-library/logo/DteStyledLogo';
import { NewPageTemplate } from '../../component-library/page-templates/NewPageTemplate';

import { AgencyPaymentMainContent } from './main-content/AgencyPaymentMainContent';
import { DefaultMainContent } from './main-content/DefaultMainContent';
import { useCLockViewModel } from './useCLockViewModel';

const CLockView = (): React.ReactElement => {
  const {
    eligibilityResponse,
    onPageViewHandler,
    agencyPhoneNumberOnClickHandler,
    energyAssistanceProgramsLinkOnClickHandler,
    unitedWayLinkOnClickHandler,
    viewMyAccountHistoryClickHandler,
    viewCurrentBillClickHandler,
  } = useCLockViewModel();
  const eligibility = eligibilityResponse?.data;

  const showContent = eligibility?.eligible.dunningLock === 'Y';
  const showAgencyPaymentContent = !!(
    eligibility?.dunningLockDetails?.[0].agencyPaymentAmount && eligibility?.dunningLockDetails?.[0].agencyPaymentDate
  );

  useEffect(() => {
    if (showContent) {
      onPageViewHandler(showAgencyPaymentContent);
    }
  }, [showContent, showAgencyPaymentContent, onPageViewHandler]);

  const header = <DteHeader title={serviceProtection} logo={<DteStyledLogo />} />;

  const content = showAgencyPaymentContent ? (
    <AgencyPaymentMainContent
      eligibilityResponse={eligibility!}
      agencyPhoneNumberOnClickHandler={agencyPhoneNumberOnClickHandler}
      energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
      unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
      viewMyAccountHistoryClickHandler={viewMyAccountHistoryClickHandler}
      viewCurrentBillClickHandler={viewCurrentBillClickHandler}
    />
  ) : (
    <DefaultMainContent
      eligibilityResponse={eligibility!}
      agencyPhoneNumberOnClickHandler={agencyPhoneNumberOnClickHandler}
      energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
      unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
      viewMyAccountHistoryClickHandler={viewMyAccountHistoryClickHandler}
      viewCurrentBillClickHandler={viewCurrentBillClickHandler}
    />
  );

  return (
    <NewPageTemplate
      titleTag="Agency Payment Assistance | DTE Energy"
      pageHeader={header}
      showContent={showContent}
      content={content}
    />
  );
};
export default CLockView;
