import { reconnectService } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { urls } from 'constants/';
import { trackGA4Event } from 'gtm/gtag';
import { reconnectHeaderCancelClickEvent } from 'gtm/gtmEventsReconnect';

const headerCancelButtonHandler = (): void => {
  trackGA4Event(reconnectHeaderCancelClickEvent);
  window.location.href = urls.currentBillUrl;
};

export const mobileHeader = (
  <HeaderMobile titleText={reconnectService} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);
export const desktopHeader = (
  <HeaderDesktop titleText={reconnectService} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);
