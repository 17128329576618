const PageTitles = Object.freeze({
  ADMIN: 'Admin | DTE Energy',
  ERROR: 'Error | DTE Energy',
  HOW_PAYMENT_AGREEMENT_WORKS: 'How Payment Agreement Works | DTE Energy',
  PAYMENT_AGREEMENT: 'Payment Agreement | DTE Energy',
  PAYMENT_AGREEMENT_ENROLLMENT: 'Payment Agreement Enrollment | DTE Energy',
  PAYMENT_CENTER_TEST: 'Payment Center Test | DTE Energy',
  PAYMENT_EXTENSION: 'Payment Extension | DTE Energy',
  PAYMENT_EXTENSION_ELIGIBLE: 'Payment Extension Eligible | DTE Energy',
  PAYMENT_OFFERS: 'Payment Offers | DTE Energy',
  RECONNECT: 'Reconnect | DTE Energy',
  SERVICE_PROTECTION: 'Service Protection | DTE Energy',
  MY_SERVICE: 'My Service | DTE Energy',
});

export default PageTitles;
