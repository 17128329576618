import * as React from 'react';
import { useEffect } from 'react';
import { ArrowBackIosRounded } from '@mui/icons-material';
import { styled } from '@mui/material';

import {
  AdditionalAssistanceBanner,
  DefaultAssistanceMessage,
} from 'component-library/banners/AdditionalAssistanceBanner';
import { SuccessBanner } from 'component-library/banners/SuccessBanner';
import { dayjsDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import { DteHeader } from 'component-library/header/DteHeader';
import { StyledCtaLink } from 'component-library/links/StyledLink';
import { DteStyledLogo } from 'component-library/logo/DteStyledLogo';
import { NewPageTemplate } from 'component-library/page-templates/NewPageTemplate';
import { Bold } from 'component-library/typography/Bold';

import { currentBillUrl } from '../../urls-and-paths';
import { LockStatusContent } from '../LockStatusContent';

import { DocumentSubmissionGuidelines } from './document-submission-guidelines/DocumentSubmissionGuidelines';
import { RequiredDocuments } from './required-documents/RequiredDocuments';
import { useYLockViewModel } from './useYLockViewModel';

export const YLockView = (): React.ReactElement => {
  const {
    eligibilityResponse,
    onPageViewHandler,
    viewCurrentBillOnClickHandler,
    energyAssistanceProgramsLinkOnClickHandler,
    unitedWayLinkOnClickHandler,
    submitDocumentsOnClickHandler,
  } = useYLockViewModel();
  const eligibility = eligibilityResponse?.data;

  const showContent = eligibility?.eligible.dunningLock === 'Y';

  useEffect(() => {
    if (showContent) {
      onPageViewHandler();
    }
  }, [showContent, onPageViewHandler]);

  const lockEndDate: string = eligibility?.dunningLockDetails
    ? dayjsDate(eligibility.dunningLockDetails[0].endDate).format('dddd, MMMM D, YYYY')
    : '';

  const TopContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  });
  const BannerAndInstructions = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  }));

  const header = <DteHeader title="Service Protection" logo={<DteStyledLogo />} />;

  const content = (
    <LockStatusContent>
      <TopContent>
        <StyledCtaLink href={currentBillUrl} sx={{ marginBottom: '-1px' }} onClick={viewCurrentBillOnClickHandler}>
          <ArrowBackIosRounded sx={{ fontSize: '18px' }} />
          <Bold>View Current Bill</Bold>
        </StyledCtaLink>
        <BannerAndInstructions>
          <SuccessBanner
            message={`Your DTE account is temporarily protected from a service interruption through ${lockEndDate}.`}
          />
          <RequiredDocuments lockEndDate={lockEndDate} submitDocumentsOnClickHandler={submitDocumentsOnClickHandler} />
        </BannerAndInstructions>
      </TopContent>
      <DocumentSubmissionGuidelines />
      <AdditionalAssistanceBanner
        message={
          <DefaultAssistanceMessage
            energyAssistanceProgramsLinkOnClickHandler={energyAssistanceProgramsLinkOnClickHandler}
            unitedWayLinkOnClickHandler={unitedWayLinkOnClickHandler}
          />
        }
      />
    </LockStatusContent>
  );

  return (
    <NewPageTemplate
      titleTag="Income Eligible Review | DTE Energy"
      pageHeader={header}
      showContent={showContent}
      content={content}
    />
  );
};
