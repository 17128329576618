import { PatternFormat } from 'react-number-format';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { AccountDetailsResponse } from 'api/account-details-api/AccountDetailsResponse';
import { useResponsiveSx } from 'theme/breakpoints/responsiveHooks';

interface Props {
  accountInfo?: AccountDetailsResponse;
  accountNumber?: number;
}

const PaymentExtensionAccountInformation = ({ accountInfo, accountNumber }: Props): React.ReactElement => {
  const theme = useTheme();
  const address = accountInfo?.accounts[0].serviceAddresses[0];

  const sx: Record<string, SxProps> = {
    accountInfo: {
      display: 'flex',
      flexDirection: 'column',
      padding: '30px',
      minHeight: '185px',
      borderWidth: '1px',
      borderColor: theme.palette.dteGrey.main,
    },
    accountInfoMobile: {
      alignItems: 'center',
      width: '100%',
      borderStyle: 'none none solid none',
    },
    accountInfoDesktop: {
      alignItems: 'flex-start',
      width: '50%',
      borderStyle: 'none solid none none',
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    body: {
      color: theme.palette.secondary.main,
    },
  };

  const accountInfoMobileSx = {
    ...sx.accountInfo,
    ...sx.accountInfoMobile,
  };

  const accountInfoDesktopSx = {
    ...sx.accountInfo,
    ...sx.accountInfoDesktop,
  };

  const deviceBreakpoint = useResponsiveSx(
    accountInfoMobileSx,
    accountInfoDesktopSx,
    accountInfoDesktopSx,
    accountInfoMobileSx,
  );

  return (
    <Box sx={deviceBreakpoint} data-testid="pe-account-info-border">
      <Typography data-testid="pe-account-info-name" sx={sx.title} variant="body2">
        {accountInfo?.customerName}
      </Typography>
      <Typography data-testid="pe-account-info-address-1" sx={sx.body} variant="body1">
        {address?.addressLine1}
      </Typography>
      {address?.addressLine2 && (
        <Typography sx={sx.body} variant="body1" data-testid="pe-account-info-address-2">
          {address?.addressLine2}
        </Typography>
      )}
      <Typography sx={sx.body} variant="body1" data-testid="pe-account-info-city-state-zip">
        {`${address?.city}, ${address?.state} ${address?.zipcode}`}
      </Typography>
      <Typography sx={sx.title} paddingTop="12px" variant="body1" data-testid="pe-account-info-account-number-title">
        Account Number
      </Typography>
      <Typography sx={sx.body} variant="body1" data-testid="pe-account-info-user-account-number">
        <PatternFormat displayType="text" value={accountNumber} format="#### ### #### #" />
      </Typography>
    </Box>
  );
};

export default PaymentExtensionAccountInformation;
