import { useResponsiveRendering } from '../../theme/breakpoints/responsiveHooks';

import DteTooltip from './DteTooltip';

interface ResponseTooltipProps {
  popoverText: string;
}

// *** TODO: Separate tablet and desktop tooltips and enable placement as configurable for each BP
const ResponsiveTooltip = ({ popoverText }: ResponseTooltipProps): React.ReactElement => {
  const TabletAndDesktopTooltip = <DteTooltip placement="top" popoverText={popoverText} />;

  const MobileTooltip = <DteTooltip placement="top" popoverText={popoverText} />;

  const responsiveOptionSelect = useResponsiveRendering(
    MobileTooltip,
    TabletAndDesktopTooltip,
    TabletAndDesktopTooltip,
  );

  return <>{responsiveOptionSelect}</>;
};

export default ResponsiveTooltip;
