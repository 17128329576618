import { useEligibility } from 'api/eligibility-api/eligibility-api';

import { useUserState } from '../../auth/authenticate';
import { unitedWayPhoneNumber } from '../../collections-copy';
import { trackGA4Event } from '../../gtm/gtag';

import {
  wLockCompleteFormOnClickEvent,
  wLockEnergyAssistanceProgramsLinkOnClickEvent,
  wLockMedicalTabOnClickEvent,
  wLockMilitaryTabOnClickEvent,
  wLockUnitedWayLinkOnClickEvent,
  wLockUploadFormOnClickEvent,
  wLockViewCurrentBillLinkOnClickEvent,
  wLockViewEvent,
  wLockViewMedicalLinkOnClickEvent,
  wLockViewMilitaryLinkOnClickEvent,
} from './gtmEventsWLock';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useWLockViewModel = () => {
  const { isAuthenticated } = useUserState();

  const { isEligibilitySuccess, isEligibilityError, eligibilityResponse, eligibilityError } = useEligibility({
    queryKey: ['lock-status-eligibility'],
    enabled: isAuthenticated,
  });

  const onPageViewHandler = (): void => {
    trackGA4Event(wLockViewEvent);
  };

  const medicalTabOnClickHandler = (): void => {
    trackGA4Event(wLockMedicalTabOnClickEvent);
  };

  const militaryTabOnClickHandler = (): void => {
    trackGA4Event(wLockMilitaryTabOnClickEvent);
  };

  const viewCurrentBillOnClickHandler = (): void => {
    trackGA4Event(wLockViewCurrentBillLinkOnClickEvent);
  };

  const completeFormOnClickHandler = (): void => {
    trackGA4Event(wLockCompleteFormOnClickEvent);
  };

  const uploadFormOnClickHandler = (): void => {
    trackGA4Event(wLockUploadFormOnClickEvent);
  };

  const energyAssistanceProgramsLinkOnClickHandler = (): void => {
    trackGA4Event(wLockEnergyAssistanceProgramsLinkOnClickEvent);
  };

  const unitedWayLinkOnClickHandler = (): void => {
    trackGA4Event({
      ...wLockUnitedWayLinkOnClickEvent,
      actionObject: unitedWayPhoneNumber,
    });
  };

  const scrollToTop = (): void => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  };

  const viewMilitaryLinkOnClickHandler = (): void => {
    trackGA4Event(wLockViewMilitaryLinkOnClickEvent);
    scrollToTop();
  };

  const viewMedicalLinkOnClickHandler = (): void => {
    trackGA4Event(wLockViewMedicalLinkOnClickEvent);
    scrollToTop();
  };

  return {
    isEligibilitySuccess,
    isEligibilityError,
    eligibilityResponse,
    eligibilityError,
    onPageViewHandler,
    medicalTabOnClickHandler,
    militaryTabOnClickHandler,
    viewCurrentBillOnClickHandler,
    completeFormOnClickHandler,
    uploadFormOnClickHandler,
    energyAssistanceProgramsLinkOnClickHandler,
    unitedWayLinkOnClickHandler,
    viewMilitaryLinkOnClickHandler,
    viewMedicalLinkOnClickHandler,
  };
};
