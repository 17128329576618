import { AccountDetailsResponse } from './account-details-api/AccountDetailsResponse';
import { BillComparisonResponse } from './bill-comparison-api/BillComparisonResponse';
import { CombinedEligibilityCheckResponse } from './eligibility-api/EligibilityCheckResponse';
import ExperianResponse from './experian-api/ExperianResponse';
import { LockResponse } from './payment-extension-lock-api/LockResponse';
import RestoreServiceResponse from './restore-service-api/RestoreServiceResponse';

export default class Mocks {
  static experianResponseBody: ExperianResponse = {
    businessID: 1102101903,
    mimoFlag: true,
    experianFlag: true,
    disclaimerFlag: false,
  };

  static combinedEligibilityResponseBody: CombinedEligibilityCheckResponse = {
    eligible: {
      paymentExtension: 'Y',
      restore: 'N',
      dunningLock: 'N',
    },
    paymentExtensionDetails: {
      pastDueAmount: '100.00',
      shutOffDate: '2023-04-02',
    },
    messageReply: {
      replyCode: 'Success',
      error: [{}],
    },
  };

  static accountDetailsResponseBody: AccountDetailsResponse = {
    customerName: 'John Doe',
    accounts: [
      {
        accountNumber: '1234567890',
        accountType: 'mocked',
        serviceAddresses: [
          {
            addressLine1: '123 Fake St.',
            addressLine2: 'Apt 1',
            city: 'Detroit',
            state: 'MI',
            zipcode: '12345',
          },
        ],
      },
    ],
  };

  static billComparisonResponseBody: BillComparisonResponse = {
    billComparison: {
      accountNumber: '123456789012',
      currentCycle: {
        averageDailyTemperatures: {
          average: 10,
          averageTemperatures: [
            {
              date: new Date(),
              temp: 10,
            },
          ],
        },
        billAmount: 26,
        dayCount: 31,
        endDate: new Date(),
        products: [
          {
            type: '',
            unit: '',
            usage: 10,
          },
        ],
        startDate: new Date(),
      },
      siteId: '12345',
    },
  };

  static restoreServiceResponseBody: RestoreServiceResponse = {
    serviceOrderNo: 1234567890,
    messageReply: {
      replyCode: 'Success',
    },
  };

  static successfulLockResponse: LockResponse = {
    messageReply: {
      replyCode: 'Success',
    },
  };

  static paymentAgreementDetailsResponseBody: IPaymentAgreementDetailsResponse = {
    paymentAgreementDetails: {
      cashOnly: 'N',
      downPaymentAmount: '500.00',
      downPaymentDueDate: '2025-04-30',
      downPaymentDueDayOfWeek: 'Monday',
      downPaymentPercentage: 50,
      pastDueBalance: '500.00',
      paymentAgreement: 'NA',

      paymentOptions: [
        {
          installmentDuration: '3',
          installmentAmount: '50.00',
        },
        {
          installmentDuration: '4',
          installmentAmount: '50.00',
        },
        {
          installmentDuration: '5',
          installmentAmount: '50.00',
        },
        {
          installmentDuration: '6',
          installmentAmount: '50.00',
        },
      ],

      shutOffAmount: '500.00',
      shutOffDate: '2024-04-30',
      totalAccountBalance: '1500.00',
      totalPABalance: '500.00',
    },

    messageReply: {
      replyCode: 'Success',
      error: [],
    },
  };

  static paymentAgreementDetailsEnrolledBody: IPaymentAgreementDetailsResponse = {
    paymentAgreementDetails: {
      cashOnly: 'N',
      downPaymentAmount: '500.00',
      downPaymentPercentage: 0,
      downPaymentPostedDate: '2025-04-30',

      enrollDetails: {
        enrollmentDate: '2024-04-30',
        numberOfInstallmentsOpted: 5,
        installmentAmount: '100.00',
        numberOfInstallmentsRemaining: 0,
        remainingPABalance: '500.00',
      },

      pastDueBalance: '500.00',
      paymentAgreement: 'E',

      shutOffAmount: '500.00',
      shutOffDate: '2024-04-30',
      totalAccountBalance: '1500.00',
      totalPABalance: '500.00',
    },

    messageReply: {
      replyCode: 'Success',
      error: [],
    },
  };

  static createPAPlan: ICreatePaymentAgreementPlanResponse = {
    documentNumber: 123456789,
    messageReply: {
      replyCode: 'Success',
      error: [],
    },
  };
}
