import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, SxProps, TextField, Typography, useTheme } from '@mui/material';

import DteErrorIcon from 'component-library/icons/DteErrorIcon';

import { PaymentOptionsContext } from '../PaymentOptionsContext';
import { PaymentOption } from '../PaymentOptionsModels';

import DollarStartAdornment from './DollarStartAdornment';
import NumberFormatCustom from './NumberFormatCustom';

const RadioLabelWithInput: React.FC = (): React.ReactElement => {
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '215px',
      paddingLeft: '22px',
    },
    description: {
      paddingBottom: '8px',
      color: theme.palette.primary.main,
    },
    failDescription: {
      paddingBottom: '8px',
      color: theme.palette.dteRed.dark,
      display: 'flex',
      gap: '8px',
      width: '215px',
      alignItems: 'center',
    },
    textField: {
      '.MuiInputBase-root': {
        height: '36px',
        width: '181px',
      },
      '.MuiInputBase-input': {
        ...theme.typography.input,
        color: theme.palette.primary.main,
        padding: '8px 12px',
        width: '120px',
      },
      '.MuiOutlinedInput-root': {
        borderRadius: 'unset',
        border: `1px solid ${theme.palette.dteGrey.dark}`,
        paddingLeft: '0px',
        ':hover': {
          border: `1px solid ${theme.palette.dteBlue.dark}`,
          outline: 'unset',
          '.MuiInputAdornment-root': {
            borderRight: `1px solid ${theme.palette.dteBlue.dark}`,
            borderTop: `1px solid ${theme.palette.dteBlue.dark}`,
            borderBottom: `1px solid ${theme.palette.dteBlue.dark}`,
          },
        },
      },
      '.MuiOutlinedInput-root.Mui-focused': {
        border: `1px solid ${theme.palette.dteBlue.dark}`,
        outline: 'unset',
        '.MuiInputAdornment-root': {
          borderRight: `1px solid ${theme.palette.dteBlue.dark}`,
          borderTop: `1px solid ${theme.palette.dteBlue.dark}`,
          borderBottom: `1px solid ${theme.palette.dteBlue.dark}`,
        },
      },
      '.MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },
    },
    failTextField: {
      '.MuiInputBase-root': {
        height: '36px',
        width: '181px',
        backgroundColor: theme.palette.dteRed.light,
      },
      '.MuiInputBase-input': {
        ...theme.typography.input,
        color: theme.palette.primary.main,
        padding: '8px 12px',
        width: '120px',
      },
      '.MuiOutlinedInput-root': {
        borderRadius: 'unset',
        border: `1px solid ${theme.palette.dteRed.dark}`,
        paddingLeft: '0px',
        ':hover': {
          border: `1px solid ${theme.palette.dteRed.dark}`,
          outline: 'unset',
          '.MuiInputAdornment-root': {
            borderRight: `1px solid ${theme.palette.dteRed.dark}`,
            borderTop: `1px solid ${theme.palette.dteRed.dark}`,
            borderBottom: `1px solid ${theme.palette.dteRed.dark}`,
          },
        },
      },
      '.MuiOutlinedInput-root.Mui-focused': {
        border: `1px solid ${theme.palette.dteRed.dark}`,
        outline: 'unset',
        '.MuiInputAdornment-root': {
          borderRight: `1px solid ${theme.palette.dteRed.dark}`,
          borderTop: `1px solid ${theme.palette.dteRed.dark}`,
          borderBottom: `1px solid ${theme.palette.dteRed.dark}`,
        },
      },
      '.MuiOutlinedInput-notchedOutline': {
        border: 'unset',
      },
    },
    payAnotherAmtErrorMessage: {
      paddingTop: '4px',
      color: `${theme.palette.dteRed.dark}`,
    },
  };

  const { inputValue, setInputValue } = useContext(PaymentOptionsContext);
  const { setValue, getValues, formState, clearErrors } = useFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (Number(event.target.value) > 0) {
      clearErrors('PaymentOptions');
    }
    if (getValues('PaymentOptions') !== PaymentOption.PAY_ANOTHER_AMOUNT) {
      setValue('PaymentOptions', PaymentOption.PAY_ANOTHER_AMOUNT);
    }
    setInputValue(event.target.value);
  };

  const inputCurrencyMask = (value: string): string => {
    if (!value) {
      return '';
    }
    const formattedNumber = parseFloat(value.replace(/,/g, ''));
    const newDollarValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
      .format(formattedNumber)
      .replace(/\$/g, '');
    return newDollarValue;
  };

  const handleOnBlur = (): void => {
    const formattedValue = inputCurrencyMask(inputValue);
    setInputValue(formattedValue);
  };

  const descriptionValidationsSx =
    formState.errors.PaymentOptions?.type !== 'inputEmptyOrZero' ? sx.description : sx.failDescription;
  const textFieldValidationsSx =
    formState.errors.PaymentOptions?.type !== 'inputEmptyOrZero' ? sx.textField : sx.failTextField;

  return (
    <Box sx={sx.rootWrapper}>
      <Box sx={descriptionValidationsSx}>
        {formState.errors.PaymentOptions?.type === 'inputEmptyOrZero' ? <DteErrorIcon /> : <></>}
        <Typography data-testid="radio-label-pay-another-amount" fontWeight={600} variant="body2">
          Pay Another Amount
        </Typography>
      </Box>
      <TextField
        value={inputValue}
        onClick={(): void => setValue('PaymentOptions', PaymentOption.PAY_ANOTHER_AMOUNT)}
        onChange={handleChange}
        onBlur={handleOnBlur}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: NumberFormatCustom as any,
          startAdornment: <DollarStartAdornment />,
        }}
        variant="outlined"
        sx={textFieldValidationsSx}
      />
      {formState.errors.PaymentOptions?.type === 'inputEmptyOrZero' ? (
        <Typography data-testid="pay-another-amount-error-message" sx={sx.payAnotherAmtErrorMessage} variant="body1">
          Please provide a payment amount.
        </Typography>
      ) : (
        ''
      )}
    </Box>
  );
};

export default RadioLabelWithInput;
