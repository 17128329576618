import { MonthlyPayment } from './monthly-payment';
import { PaymentDetails } from './payment-details';

interface IEnrollmentDetailsProps {
  downPaymentAmount: string;
  downPaymentStatus: string;
  installmentAmount: string;
  numberOfInstallmentsOpted: number;
  numberOfInstallmentsRemaining: number;
}

const EnrollmentDetails: React.FC<IEnrollmentDetailsProps> = (props: IEnrollmentDetailsProps): React.ReactElement => {
  const {
    downPaymentAmount,
    downPaymentStatus,
    installmentAmount,
    numberOfInstallmentsOpted,
    numberOfInstallmentsRemaining,
  } = props;
  return (
    <>
      <PaymentDetails
        downPaymentAmount={downPaymentAmount}
        downPaymentStatus={downPaymentStatus}
        installmentAmount={installmentAmount}
        numberOfInstallmentsOpted={numberOfInstallmentsOpted}
      />
      <MonthlyPayment
        numberOfInstallmentsOpted={numberOfInstallmentsOpted}
        numberOfInstallmentsRemaining={numberOfInstallmentsRemaining}
      />
    </>
  );
};
export default EnrollmentDetails;
