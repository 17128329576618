import { useState } from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Accordion, AccordionDetails, AccordionSummary, Box, SxProps, Typography, useTheme } from '@mui/material';

import { trackGA4Event } from 'gtm/gtag';
import { reconnectDetailsAccordionClickEvent } from 'gtm/gtmEventsReconnect';

type Props = {
  ariaIdLabel: string;
  accordionContent: JSX.Element;
};

const PaymentOptionsAccordion: React.FC<Props> = (props: Props): JSX.Element => {
  const { ariaIdLabel, accordionContent } = props;
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    accordionRootWrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
      boxShadow: 'none',
      '.MuiAccordionSummary-root': {
        height: '41px',
        minHeight: '41px',
      },
      '.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '41px',
      },
      '.MuiAccordionSummary-content': {
        color: theme.palette.dteBlue.main,
        flexGrow: '0',
        paddingRight: '7px',
      },
    },
    accordionDetails: {
      padding: '0px',
    },
    accordionDetailsExpanded: {
      borderTop: '1px solid #D9D9D9',
    },
    accordionExpandIcon: {
      color: theme.palette.dteBlue.main,
    },
  };

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleChange = (): void => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleChange} sx={sx.accordionRootWrapper}>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon data-testid="accordion-expand-icon" sx={sx.accordionExpandIcon} />}
        aria-controls={`${ariaIdLabel}-content`}
        id={`${ariaIdLabel}-summary`}
      >
        <Typography
          onClick={(): void => trackGA4Event(reconnectDetailsAccordionClickEvent(ariaIdLabel, isExpanded))}
          data-testid="accordion-expand-label"
          fontWeight={600}
          variant="body2"
        >
          {isExpanded ? 'Hide Details' : 'View Details'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={sx.accordionDetails}>
        <Box sx={sx.accordionDetailsExpanded}>{accordionContent}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PaymentOptionsAccordion;
