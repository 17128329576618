import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { useRestoreService } from 'api';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { routes } from 'constants/';
import { useRestoreDetails } from 'hooks';
import { logger } from 'services';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import ReconnectConfirmationContent from '../components/ReconnectConfirmationContent';

import { desktopHeader, mobileHeader } from './ReconnectConfirmationPageHeaders';

const ReconnectConfirmationPage: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  const { mutateRestore, restoreResponse } = useRestoreService({
    mutationKey: ['restore-service'],
    onError: (error: unknown) => {
      logger.info('[Reconnect Confirmation Page] Restore Service API Call Error!', error);

      navigate(routes.error.reconnect);
    },
    onSuccess: (response: AxiosResponse<RestoreServiceResponse>) => {
      logger.info('[Reconnect Confirmation Page] Restore Service API Call Success!', response);
      if (!response.data.serviceOrderNo) {
        logger.info(
          '[Reconnect Confirmation Page] Restore Service API Call Service Order Number Empty! Navigate to Error Page',
        );

        navigate(routes.error.reconnect);
      }
    },
  });

  const { isRestoreDetailsSuccess, restoreDetails } = useRestoreDetails();
  const { contractAccountNo, installation, premise } = restoreDetails;

  useEffect((): void => {
    if (isRestoreDetailsSuccess && restoreDetails) {
      const webUserId = localStorage.getItem('webUserId');
      const depositAmount = localStorage.getItem(`depositAmount_${webUserId}`);
      const reconnectionFee = localStorage.getItem(`reconnectionFee_${webUserId}`);
      const totalAmountPastDue = localStorage.getItem(`totalAmountPastDue_${webUserId}`);

      const restoreServiceBody: RestoreServiceRequest = {
        contractAccountNumber: contractAccountNo!,
        depositAmount: parseFloat(depositAmount!),
        installationNumber: installation!,
        pastDueAmount: parseFloat(totalAmountPastDue!),
        premiseId: premise!,
        reconnectionFee: parseFloat(reconnectionFee!),
      };

      mutateRestore(restoreServiceBody);
    }
  }, [isRestoreDetailsSuccess, restoreDetails]);

  return (
    <>
      <Helmet>
        <title>Reconnect Service Confirmation | DTE Energy</title>
      </Helmet>
      <PageTemplate
        allViewportPaddingTop="0px"
        dataTestId="reconnect-confirmation-container"
        header={pageHeader}
        tabletDesktopContentWidth="630px"
      >
        {restoreResponse?.data.serviceOrderNo ? <ReconnectConfirmationContent /> : <LoadingSpinner />}
      </PageTemplate>
    </>
  );
};

export default ReconnectConfirmationPage;
