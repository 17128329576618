import { gtmBaseEvent } from '../../constants';
import logger from '../logger';

import getAuthenticationStatus from './getAuthenticationStatus';
import pushDataLayer from './pushDataLayer';

type GtmTracViewEventProps = GtmEventProps;

const trackViewEvent = (params: GtmTracViewEventProps): void => {
  const { actionDetail, actionObject, authenticationStatus, transactionStepName, transactionType, userType } = params;
  const authStatus = getAuthenticationStatus(authenticationStatus);

  const event: IGtmEventProps = {
    ...gtmBaseEvent,
    action_detail: actionDetail,
    action_object: actionObject,
    action_type: 'view',
    authentication_status: authStatus,
    transaction_step_name: transactionStepName,
    transaction_type: transactionType,
    user_type: userType,
  };

  logger.info(`[Google Tag Manager] Sending tags for view events`, event);

  pushDataLayer(event);
};

export default trackViewEvent;
