import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { accountDetailsApi } from '../api/account-details-api/account-details-api';
import { useUserState } from '../auth/authenticate';

interface IProps {
  errorPagePath: string;
}

interface IUseAccountDetailsData {
  isAccountDetailsSuccess: boolean;
  accountDetails: IAccountDetailsResponse;
}

const useAccountDetails = ({ errorPagePath }: IProps): IUseAccountDetailsData => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();

  const { isSuccess: isAccountDetailsSuccess, data: accountDetailsResponse } = useQuery({
    queryKey: ['account-details'],
    queryFn: accountDetailsApi,
    enabled: isAuthenticated,
    retry: 0,
    refetchOnWindowFocus: false,
    // eslint-disable-next-line @typescript-eslint/typedef
    onSuccess: (httpResponse) => {
      const { data } = httpResponse;
      if (!data?.accounts.length) {
        navigate(errorPagePath);
      }
    },
    // eslint-disable-next-line @typescript-eslint/typedef
    onError: () => {
      navigate(errorPagePath);
    },
  });

  if (accountDetailsResponse?.data?.accounts.length) {
    return { isAccountDetailsSuccess, accountDetails: accountDetailsResponse.data };
  }
  return {
    isAccountDetailsSuccess,
    accountDetails: {
      customerName: '',
      accounts: [],
    },
  };
};

export default useAccountDetails;
