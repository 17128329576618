import { Helmet } from 'react-helmet-async';

import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import PageTitles from 'constants/pageTitles';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import PaymentExtensionEligibleContent from './PaymentExtensionEligibleContent';
import { desktopHeader, mobileHeader } from './PaymentExtensionEligibleHeader';

const PaymentExtensionEligiblePage = (): React.ReactElement => {
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  return (
    <>
      <Helmet>
        <title>{PageTitles.PAYMENT_EXTENSION_ELIGIBLE}</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="16px"
        tabletDesktopContentWidth="740px"
        dataTestId="payment-extension-eligible-page"
      >
        <PaymentExtensionEligibleContent />
      </PageTemplate>
    </>
  );
};

export default PaymentExtensionEligiblePage;
