import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { useEligibility } from 'api/eligibility-api/eligibility-api';
import { useRestoreService } from 'api/restore-service-api/restore-service-api';
import RestoreServiceRequest from 'api/restore-service-api/RestoreServiceRequest';
import RestoreServiceResponse from 'api/restore-service-api/RestoreServiceResponse';
import { useUserState } from 'auth/authenticate';
import { reconnectService } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';
import { currentBillUrl, reconnectGenericErrorPath } from 'urls-and-paths';

import ReconnectConfirmationContent from './components/ReconnectConfirmationContent';

const mobileHeader = <HeaderMobile titleText={reconnectService} isHeaderButtonRendered={false} />;
const desktopHeader = <HeaderDesktop titleText={reconnectService} isHeaderButtonRendered={false} />;

const ReconnectConfirmationPage: React.FC = (): React.ReactElement => {
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);
  const { isAuthenticated } = useUserState();
  const navigate = useNavigate();

  const { restoreResponse, mutateRestore } = useRestoreService({
    mutationKey: ['restore-service'],
    onSuccess: (response: AxiosResponse<RestoreServiceResponse>) => {
      if (!response.data.serviceOrderNo) {
        navigate(reconnectGenericErrorPath);
      }
    },
    onError: () => {
      /* navigate to error page */
      navigate(reconnectGenericErrorPath);
    },
  });
  useEligibility({
    queryKey: ['reconnect-confirmation-eligibility-check'],
    enabled: isAuthenticated,
    onSuccess: (httpResponse: AxiosResponse<CombinedEligibilityCheckResponse>) => {
      if (httpResponse.data?.restoreDetails) {
        const { eligibilityFlag, premise, contractAccountNo, installation } = httpResponse.data.restoreDetails;

        // Not Eligible for Restore via WebReconnect, Eligibility
        if (!eligibilityFlag) {
          window.location.href = currentBillUrl;
        }

        // Restore Service
        const webUserId = localStorage.getItem('webUserId');
        const depositAmount = localStorage.getItem(`depositAmount_${webUserId}`);
        const totalAmountPastDue = localStorage.getItem(`totalAmountPastDue_${webUserId}`);
        const reconnectionFee = localStorage.getItem(`reconnectionFee_${webUserId}`);

        const restoreServiceBody: RestoreServiceRequest = {
          contractAccountNumber: contractAccountNo,
          depositAmount: parseFloat(depositAmount!),
          installationNumber: installation,
          premiseId: premise,
          reconnectionFee: parseFloat(reconnectionFee!),
          pastDueAmount: parseFloat(totalAmountPastDue!),
        };

        mutateRestore(restoreServiceBody);
      } else {
        /* navigate to error page */
        navigate(reconnectGenericErrorPath);
      }
    },
    onError: () => {
      /* navigate to error page */
      navigate(reconnectGenericErrorPath);
    },
  });

  return (
    <>
      <Helmet>
        <title>Reconnect Service Confirmation | DTE Energy</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="0px"
        tabletDesktopContentWidth="630px"
        dataTestId="reconnect-confirmation-container"
      >
        {restoreResponse?.data.serviceOrderNo ? <ReconnectConfirmationContent /> : <LoadingSpinner />}
      </PageTemplate>
    </>
  );
};

export default ReconnectConfirmationPage;
