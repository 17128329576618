export const mockBaseSuccessRestoreDetails: IRestoreDetailsResponse = {
  messageReply: {
    error: [],
    replyCode: 'Success',
  },
  restoreDetails: {
    cardAllowedIndicator: true,
    cashAllowedIndicator: true,
    checkAllowedIndicator: true,
    contractAccountNo: 920041350595,
    contractAccountCategory: '01',
    depositAmount: '0.00',
    installation: 7002788599,
    eligibilityFlag: true,
    merchantFeeIndicator: false,
    premise: 5111923237,
    reconnectionFee: '20.00',
    totalPastDueAmount: '100.00',
    totalAmount: '20.00',
  },
};

export const mockBaseSuccessRestoreDetailsInEligible: IRestoreDetailsResponse = {
  messageReply: {
    error: [],
    replyCode: 'Success',
  },
  restoreDetails: {
    contractAccountNo: 920041350595,
    depositAmount: '0.00',
    eligibilityFlag: false,
    reconnectionFee: '0.00',
    totalAmount: '0.00',
    totalPastDueAmount: '0.00',
  },
};
