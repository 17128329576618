import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, SxProps } from '@mui/material';
import { AxiosResponse } from 'axios';

import { useAccountDetails } from 'api/account-details-api/account-details-api';
import { useEligibility } from 'api/eligibility-api/eligibility-api';
import { LockResponse } from 'api/payment-extension-lock-api/LockResponse';
import { useLock } from 'api/payment-extension-lock-api/payment-extension-lock-api';
import { useUserState } from 'auth/authenticate';
import { DtePrimaryButton } from 'component-library/buttons/DtePrimaryButton';
import { dayjsDate } from 'component-library/dates/dayjsApStyleAbbreviateMonths';
import { DteCtaLink } from 'component-library/links/DteCtaLink';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { trackGA4Event } from 'gtm/gtag';
import {
  peScheduledDetailsViewEvent,
  peScheduledViewCurrentBillClickEvent,
  peScheduledViewScheduledPaymentClickEvent,
} from 'gtm/gtmEventsPaymentExtension';
import { logger } from 'services';
import { accountHistoryUrlFromSchedueledPe, currentBillUrl, peGenericErrorPath } from 'urls-and-paths';

import { PaymentExtensionDate } from '../components/PaymentExtensionDate';
import { PaymentExtensionScheduledHeading } from '../components/PaymentExtensionScheduledHeading';
import { PaymentExtensionScheduledNotice } from '../components/PaymentExtensionScheduledNotice';

export const PaymentExtensionScheduledContent = (): React.ReactElement => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();
  const { isAccountDetailsSuccess, accountDetailsResponse } = useAccountDetails({
    enabled: isAuthenticated,
    onSuccess: (httpResponse: AxiosResponse<IAccountDetailsResponse>) => {
      const { data } = httpResponse;
      if (!data.accounts[0].accountNumber) {
        logger.info('accountDetails response is missing AccountNumber');
        navigate(peGenericErrorPath);
      }
    },
    onError: (error: unknown) => {
      logger.info('accountDetails Error', error);
      navigate(peGenericErrorPath);
    },
  });

  const [fetchCounter] = useState<number>(0);

  // TODO - will remove after we confirm the refetch is working
  //  this is a safety measure while we're testing refetch of eligibility, so it doesn't infinite loop in the environment
  const refetchEligibilityCheck = (): void => {
    if (fetchCounter <= 1) {
      // max 2 fetches
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      refetchEligibility();
    }
  };

  /*
  user lands on this page and:
    - eligibilityResponse is Y. lock is applied. eligibility is refetched and returned as S.
    - eligibilityResponse is S. lock api is not call. there is no refetch of eligibility
  */
  const { eligibilityResponse, refetchEligibility } = useEligibility({
    queryKey: ['initial-scheduled-eligibility'],
    enabled: isAuthenticated && isAccountDetailsSuccess,
    onSuccess: (httpResponse: AxiosResponse<CombinedEligibilityCheckResponse>) => {
      const response = httpResponse.data;

      trackGA4Event(peScheduledDetailsViewEvent);

      if (response.eligible.paymentExtension !== 'Y' && response.eligible.paymentExtension !== 'S') {
        logger.info('Not Eligible for Payment Extension -> routing to error page');
        navigate(peGenericErrorPath);
      }

      if (response.eligible.paymentExtension === 'Y') {
        const lockRequest = {
          contractAccountNumber: Number(accountDetailsResponse?.data.accounts[0].accountNumber),
        };

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        mutateLock(lockRequest);
      }
    },
    onError: (error: unknown) => {
      logger.info('eligibility', error);
      navigate(peGenericErrorPath);
    },
  });

  const { mutateLock } = useLock({
    mutationKey: ['payment-extension-lock'],
    onSuccess: (apiResponse: AxiosResponse<LockResponse>) => {
      if (apiResponse.data.messageReply.replyCode !== 'Success') {
        logger.info('Lock API was not successful!');
        navigate(peGenericErrorPath);
      }

      refetchEligibilityCheck();
    },
    onError: (error: unknown) => {
      logger.info('Lock API Error', error);
      navigate(peGenericErrorPath);
    },
  });

  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '40px',
      gap: '40px',
    },
    peDateButtonAndCta: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: '32px',
    },
  };

  const redirectToCurrentBill = (): void => {
    trackGA4Event(peScheduledViewCurrentBillClickEvent);
    window.location.href = currentBillUrl;
  };

  const viewScheduledPaymentHandler = (): void => {
    trackGA4Event(peScheduledViewScheduledPaymentClickEvent);
  };

  if (eligibilityResponse?.data.eligible.paymentExtension === 'S') {
    return (
      <Box sx={sx.rootWrapper}>
        <PaymentExtensionScheduledHeading />
        <Box sx={sx.peDateButtonAndCta}>
          <PaymentExtensionDate
            date={dayjsDate(eligibilityResponse!.data.paymentExtensionDetails!.lockExpiryDate!)}
            fontVariant="h2"
          />
          <DtePrimaryButton name="View Current Bill" onClick={redirectToCurrentBill} />
          <DteCtaLink
            name="View Scheduled Payment"
            href={accountHistoryUrlFromSchedueledPe}
            onClick={viewScheduledPaymentHandler}
          />
        </Box>
        <PaymentExtensionScheduledNotice
          date={dayjsDate(eligibilityResponse!.data.paymentExtensionDetails!.lockExpiryDate!)}
        />
      </Box>
    );
  }
  return <LoadingSpinner />;
};

export default PaymentExtensionScheduledContent;
