import { MutationKey, UseMutateFunction, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { reconnectServiceApiPath } from '../../urls-and-paths';
import { azurePost } from '../adapter/azure-adapter';
import { apiOrLocalMock } from '../api-or-local-mock';
import { localMockApiResponse } from '../local-mock-api-response';
import Mocks from '../Mocks';

import RestoreServiceRequest from './RestoreServiceRequest';
import RestoreServiceResponse from './RestoreServiceResponse';

const restoreService = async (body: RestoreServiceRequest): Promise<AxiosResponse<RestoreServiceResponse>> =>
  azurePost<RestoreServiceResponse>(reconnectServiceApiPath, body);

const localMockRestoreService = async (): Promise<AxiosResponse<RestoreServiceResponse>> =>
  localMockApiResponse(Mocks.restoreServiceResponseBody);

export const restoreServiceApi = apiOrLocalMock(restoreService, localMockRestoreService);

interface RestoreServiceMutationProps {
  mutationKey: MutationKey | undefined;
  onSuccess?: (httpResponse: AxiosResponse<RestoreServiceResponse>) => void;
  onError: (error: unknown) => void;
}

interface IUseRestoreServiceResponse {
  restoreResponse: AxiosResponse<RestoreServiceResponse, unknown> | undefined;
  mutateRestore: UseMutateFunction<
    AxiosResponse<RestoreServiceResponse, unknown>,
    unknown,
    RestoreServiceRequest,
    unknown
  >;
}

export const useRestoreService = ({
  mutationKey,
  onSuccess,
  onError,
}: RestoreServiceMutationProps): IUseRestoreServiceResponse => {
  const { data: restoreResponse, mutate: mutateRestore } = useMutation({
    mutationKey,
    mutationFn: (restoreServiceRequest: RestoreServiceRequest) => restoreServiceApi(restoreServiceRequest),
    onSuccess,
    onError,
  });

  return {
    restoreResponse,
    mutateRestore,
  };
};
