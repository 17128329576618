import { gtmBaseEvent } from '../../constants';
import logger from '../logger';

import getAuthenticationStatus from './getAuthenticationStatus';
import pushDataLayer from './pushDataLayer';

type GtmTrackApiProps = GtmEventPropsWithActionType;

const trackApi = (params: GtmTrackApiProps): void => {
  const {
    actionDetail,
    actionObject,
    actionType,
    authenticationStatus,
    transactionStepName,
    transactionType,
    userType,
  } = params;
  const authStatus = getAuthenticationStatus(authenticationStatus);

  const event: IGtmEventProps = {
    ...gtmBaseEvent,
    action_detail: actionDetail,
    action_object: actionObject,
    action_type: actionType,
    authentication_status: authStatus,
    transaction_step_name: transactionStepName,
    transaction_type: transactionType,
    user_type: userType,
  };

  logger.info(`[Google Tag Manager] Sending tags for API response`, event);

  pushDataLayer(event);
};

export default trackApi;
