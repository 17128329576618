import { Helmet } from 'react-helmet-async';

import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import PEConfirmDetailsContent from './PEConfirmDetailsContent';
import { desktopHeader, mobileHeader } from './PEConfirmDetailsHeader';

const PaymentExtensionConfirmDetailsPage = (): React.ReactElement => {
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  return (
    <>
      <Helmet>
        <title>Payment Center | DTE Energy</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="16px"
        tabletDesktopContentWidth="740px"
        dataTestId="payment-extension-confirm-details-page"
      >
        <PEConfirmDetailsContent />
      </PageTemplate>
    </>
  );
};

export default PaymentExtensionConfirmDetailsPage;
