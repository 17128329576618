import { gtmBaseEvent } from '../../constants';
import logger from '../logger';

import getAuthenticationStatus from './getAuthenticationStatus';
import pushDataLayer from './pushDataLayer';

type GtmTrackButtonClickProps = GtmEventProps;

const trackButtonClick = (params: GtmTrackButtonClickProps): void => {
  const { actionDetail, actionObject, authenticationStatus, transactionStepName, transactionType, userType } = params;
  if (actionDetail && actionObject) {
    const authStatus = getAuthenticationStatus(authenticationStatus);

    const event: IGtmEventProps = {
      ...gtmBaseEvent,
      action_detail: actionDetail,
      action_object: actionObject,
      action_type: 'click',
      authentication_status: authStatus,
      transaction_step_name: transactionStepName,
      transaction_type: transactionType,
    };

    if (userType) {
      event.user_type = userType;
    }

    logger.info(`[Google Tag Manager] Sending tags for button clicks`, event);

    pushDataLayer(event);
  }
};

export default trackButtonClick;
