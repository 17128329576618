import { paymentExtension } from 'component-library/header/header';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import { urls } from 'constants/urls';
import { trackGA4Event } from 'gtm/gtag';
import { peConfirmDetailsHeaderCancelClickEvent } from 'gtm/gtmEventsPaymentExtension';

const headerCancelButtonHandler = (): void => {
  trackGA4Event(peConfirmDetailsHeaderCancelClickEvent);
  window.location.href = urls.currentBillUrl;
};

export const mobileHeader = (
  <HeaderMobile titleText={paymentExtension} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);

export const desktopHeader = (
  <HeaderDesktop titleText={paymentExtension} isHeaderButtonRendered onClick={headerCancelButtonHandler} />
);
