import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, FormControlLabel, Radio, SxProps, useTheme } from '@mui/material';

import RadioDefaultIcon from 'component-library/icons/RadioDefaultIcon';
import RadioErrorIcon from 'component-library/icons/RadioErrorIcon';
import RadioSelectedIcon from 'component-library/icons/RadioSelectedIcon';

import { PaymentOptionsContext } from '../PaymentOptionsContext';
import { PaymentInfo, PaymentOption } from '../PaymentOptionsModels';

import isInputTenTimesMinimumAmountDue from './isInputTenTimesMinimumAmountDue';
import ListAmountDue from './ListAmountDue';
import PayAnotherAmtDescription from './PayAnotherAmtDescription';
import RadioLabelAdapter from './RadioLabelAdapter';
import RadioLabelMinAmount from './RadioLabelMinAmount';
import RadioLabelWithInput from './RadioLabelWithInput';
import WarningTenTimesOver from './WarningTenTimesOver';

type Props = {
  paymentInfo: PaymentInfo;
};

const OptionSelectDesktop: React.FC<Props> = (props: Props): JSX.Element => {
  const { paymentInfo } = props;
  const { inputValue } = useContext(PaymentOptionsContext);
  const theme = useTheme();
  const sx: Record<string, SxProps> = {
    rootWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    },
    optionSelectDesktopWrapper: {
      display: 'flex',
      '.MuiFormControlLabel-root': {
        width: '327px', // controls width of radio button and radio label root element
      },
      border: `1px solid ${theme.palette.dteGrey.dark}`,
    },
    failOptionSelectDesktopWrapper: {
      display: 'flex',
      '.MuiFormControlLabel-root': {
        width: '327px', // controls width of radio button and radio label root element
      },
      border: `1px solid ${theme.palette.dteRed.dark}`,
    },
    optionSelectWithWarningWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    payAnotherAmountWrapper: {
      display: 'flex',
    },
    formControlLabel: {
      margin: '0px',
      padding: '20px 30px 20px 0px',
    },
    formControlLabelWithList: {
      margin: '0px',
      borderRight: `1px solid ${theme.palette.dteGrey.main}`,
    },
    listAndDescriptionWrapper: {
      width: 'calc(100% - 327px)',
    },
    radioButton: {
      marginLeft: '10px',
      padding: '14px',
    },
  };

  const { formState } = useFormContext();
  const validationsSx =
    formState.errors.PaymentOptions?.type !== 'noOptionSelected'
      ? sx.optionSelectDesktopWrapper
      : sx.failOptionSelectDesktopWrapper;

  const optionSelectDesktopWithWarningWrapper = {
    ...validationsSx,
    ...sx.optionSelectWithWarningWrapper,
  };

  const { minimumAmountDue } = paymentInfo;

  const minAmountDueLabel = (
    <RadioLabelAdapter label={<RadioLabelMinAmount tooltipPlacement="right" />} calculatedTotal={minimumAmountDue} />
  );
  // TODO: 49944 Hiding Total Amount Due or now...
  // const totalAmountDueLabel = <RadioLabelAdapter label={<RadioLabelTotalAmount />} calculatedTotal={totalAmountDue} />;

  const dteRadioIcon =
    formState.errors.PaymentOptions?.type !== 'noOptionSelected' ? <RadioDefaultIcon /> : <RadioErrorIcon />;

  return (
    <Box sx={sx.rootWrapper} data-testid="option-selection-desktop-container">
      <Box sx={validationsSx} data-testid="option-min-amt-due-container">
        <FormControlLabel
          data-testid="min-amount-due-radio"
          value={PaymentOption.MINIMUM_AMOUNT_DUE}
          control={<Radio checkedIcon={<RadioSelectedIcon />} icon={dteRadioIcon} sx={sx.radioButton} />}
          label={minAmountDueLabel}
          sx={sx.formControlLabelWithList}
        />
        <Box sx={sx.listAndDescriptionWrapper}>
          <ListAmountDue paymentInfo={paymentInfo} isMinimumAmountDue />
        </Box>
      </Box>
      {/* // TODO: 49944 Hiding Total Amount Due or now... */}
      {/* <Box sx={validationsSx} data-testid="option-total-amt-due-container"> */}
      {/*  <FormControlLabel */}
      {/*    data-testid={'total-amount-due-radio'} */}
      {/*    value={PaymentOption.TOTAL_AMOUNT_DUE} */}
      {/*    control={<Radio checkedIcon={<RadioSelectedIcon />} icon={dteRadioIcon} sx={sx.radioButton} />} */}
      {/*    label={totalAmountDueLabel} */}
      {/*    sx={sx.formControlLabelWithList} */}
      {/*  /> */}
      {/*  <Box sx={sx.listAndDescriptionWrapper}> */}
      {/*    <ListAmountDue paymentInfo={paymentInfo} isMinimumAmountDue={false} /> */}
      {/*  </Box> */}
      {/* </Box> */}
      <Box sx={optionSelectDesktopWithWarningWrapper} data-testid="option-pay-another-amt-container">
        <Box sx={sx.payAnotherAmountWrapper}>
          <FormControlLabel
            data-testid="pay-another-amount-radio"
            value={PaymentOption.PAY_ANOTHER_AMOUNT}
            control={<Radio checkedIcon={<RadioSelectedIcon />} icon={dteRadioIcon} sx={sx.radioButton} />}
            label={<RadioLabelWithInput />}
            sx={sx.formControlLabel}
          />
          <Box sx={sx.listAndDescriptionWrapper}>
            <PayAnotherAmtDescription minAmountDue={minimumAmountDue} />
          </Box>
        </Box>
        {isInputTenTimesMinimumAmountDue(inputValue, minimumAmountDue) ? <WarningTenTimesOver /> : <></>}
      </Box>
    </Box>
  );
};

export default OptionSelectDesktop;
