import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { useAccountDetails, useBillComparison, useExperian } from 'api';
import { RestoreDetails } from 'api/eligibility-api/EligibilityCheckResponse';
import { useUserState } from 'auth/authenticate';
import PaymentProcessBanner from 'component-library/banners/PaymentProcessBanner';
import ViewCurrentBillButton from 'component-library/buttons/ViewCurrentBillButton';
import { DialogProvider } from 'component-library/dialog/DialogContext';
import { ReconnectTitle } from 'component-library/header/ServiceTitle';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { routes, urls } from 'constants/';
import { trackGA4Event } from 'gtm/gtag';
import { reconnectDetailsViewCurrentBillClickEvent } from 'gtm/gtmEventsReconnect';
import { useRestoreDetails } from 'hooks';
import { logger } from 'services';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import AccountInformationDesktop from '../components/accountInformationDesktop';
import AccountInformationMobile from '../components/accountInformationMobile';
import PaymentOptionsContainer from '../components/paymentOptions/PaymentOptionsContainer';
import { PaymentOptionsProvider } from '../components/PaymentOptionsContext';

import { desktopHeader, mobileHeader } from './ReconnectServicePageHeaders';

const ReconnectServicePage: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const { isAuthenticated } = useUserState();

  const { isAccountDetailsSuccess, accountDetailsResponse } = useAccountDetails({
    enabled: isAuthenticated,
    onError: (error: unknown): void => {
      logger.info('[Reconnect Service Page] Account Details API Call Error!', error);

      navigate(routes.error.reconnect);
    },
  });

  const { isRestoreDetailsSuccess, restoreDetails } = useRestoreDetails();

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { isExperianSuccess, experianResponse, mutateExperian } = useExperian({
    onSuccess: (response: AxiosResponse<ExperianResponse>) => {
      logger.info('[Reconnect Service Page] Experian API Call Success!', response);

      if (restoreDetails) {
        const {
          depositAmount: depositAmountString,
          eligibilityFlag,
          reconnectionFee: reconnectionFeeString,
          totalPastDueAmount: totalPastDueAmountString,
        } = restoreDetails;
        const depositAmount = parseFloat(depositAmountString);
        const reconnectionFee = parseFloat(reconnectionFeeString);
        const totalPastDueAmount = parseFloat(totalPastDueAmountString);

        if (response.data.bpemCategory === 'R887') {
          logger.info('[Reconnect Service Page] - Experian API Call Success! R887 Probate Error');

          navigate(routes.reconnect.probate);
        }

        // Interrupted Flow - now triggers AFTER experian is successful
        // TODO - Bug #52155 - restore code below once the eligibility service is fixed i.e. return correct value for reconnection fee
        if (eligibilityFlag && totalPastDueAmount + depositAmount === 0) {
          // + reconnectionFee
          navigate(routes.reconnect.confirmation, { state: { previousPath: 'reconnect-payment-options' } });
        }

        const webUserId = localStorage.getItem('webUserId');

        localStorage.setItem(`depositAmount_${webUserId}`, depositAmount.toFixed(2));
        localStorage.setItem(`reconnectionFee_${webUserId}`, reconnectionFee.toFixed(2));
        localStorage.setItem(`totalAmountPastDue_${webUserId}`, totalPastDueAmount.toFixed(2));
      } else {
        navigate(routes.error.reconnect);
      }
    },
    onError: (error: unknown) => {
      logger.info('[Reconnect Service Page] Experian API Call Error!', error);

      navigate(routes.error.reconnect);
    },
  });

  useEffect(() => {
    if (isRestoreDetailsSuccess && restoreDetails) {
      const experianRequest = {
        businessPartner: 123,
        premiseId: `${restoreDetails.premise}`,
        processId: 'WRE',
        webUserId: localStorage.getItem('webUserId') ?? '',
      };

      setTimeout(() => {
        mutateExperian(experianRequest);
      }, 500);
    }
  }, [isRestoreDetailsSuccess, restoreDetails]);

  const { contractAccountNo, depositAmount, eligibilityFlag, premise, totalPastDueAmount } = restoreDetails;
  const accountNumber = contractAccountNo ? contractAccountNo.toString() : '';
  const notZero =
    !!totalPastDueAmount && !!depositAmount && parseFloat(totalPastDueAmount) + parseFloat(depositAmount) !== 0;
  const enableBillComparisonQuery = isAuthenticated && !!premise && !!accountNumber && !!eligibilityFlag && notZero;

  const { isBillComparisonSuccess, billComparisonResponse } = useBillComparison({
    accountNumber,
    enabled: enableBillComparisonQuery,

    onError: (error: unknown) => {
      logger.info('[Reconnect Service Page] Bill Comparison API Call Error!', error);

      navigate(routes.error.reconnect);
    },
    premise,
    queryKey: ['bill-comparison', premise!, accountNumber!],
  });

  const desktopAccountInfo = (
    <AccountInformationDesktop
      accountInfo={accountDetailsResponse?.data}
      accountNumber={restoreDetails?.contractAccountNo}
    />
  );
  const mobileAccountInfo = (
    <AccountInformationMobile
      accountInfo={accountDetailsResponse?.data}
      accountNumber={restoreDetails?.contractAccountNo}
    />
  );

  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);
  const pageAccountInfo = useResponsiveRendering(mobileAccountInfo, desktopAccountInfo, desktopAccountInfo);

  const redirectToCurrentBillHandler = (): void => {
    trackGA4Event(reconnectDetailsViewCurrentBillClickEvent);
    window.location.href = urls.currentBillUrl;
  };

  const showRenderPageContent =
    isAccountDetailsSuccess &&
    isRestoreDetailsSuccess &&
    restoreDetails &&
    isBillComparisonSuccess &&
    isExperianSuccess;

  const ReconnectServicePageContent = showRenderPageContent ? (
    <>
      <ViewCurrentBillButton onClick={redirectToCurrentBillHandler} />
      <ReconnectTitle />
      {pageAccountInfo}
      <PaymentOptionsProvider>
        <DialogProvider>
          <PaymentOptionsContainer
            billComparisonResponse={billComparisonResponse!.data}
            experianResponse={experianResponse!.data}
            restoreDetails={restoreDetails as RestoreDetails}
          />
        </DialogProvider>
      </PaymentOptionsProvider>
      <PaymentProcessBanner />
    </>
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <Helmet>
        <title>Reconnect Service Payment Options | DTE Energy</title>
      </Helmet>
      <PageTemplate
        allViewportPaddingTop="16px"
        dataTestId="reconnect-service-container"
        header={pageHeader}
        tabletDesktopContentWidth="740px"
      >
        {ReconnectServicePageContent}
      </PageTemplate>
    </>
  );
};

export default ReconnectServicePage;
