import React, { SyntheticEvent, useContext, useEffect } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Button, FormControl, RadioGroup, SxProps, Typography, useTheme } from '@mui/material';

import { BillComparisonResponse } from 'api/bill-comparison-api/BillComparisonResponse';
import { RestoreDetails } from 'api/eligibility-api/EligibilityCheckResponse';
import ExperianResponse from 'api/experian-api/ExperianResponse';
import { trackGA4Event } from 'gtm/gtag';
import { reconnectDetailsMakeAPaymentClickEvent, reconnectDetailsViewEvent } from 'gtm/gtmEventsReconnect';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';
import { lambdaReconnectUrl, paymentCenterReconnectUrl, reconnectConfirmationPath } from 'urls-and-paths';

import ErrorMessage from '../ErrorMessage';
import { PaymentOptionsContext } from '../PaymentOptionsContext';
import { PaymentInfo, PaymentOption } from '../PaymentOptionsModels';

import OptionSelectDesktop from './OptionSelectDesktop';
import OptionSelectMobile from './OptionSelectMobile';
import PaymentOptionsLabel from './PaymentOptionsLabel';
import { SecurityDepositNote } from './SecurityDepositNote';

const defaultValues = {
  PaymentOptions: '',
};

interface Props {
  restoreDetails: RestoreDetails;
  experianResponse: ExperianResponse;
  billComparisonResponse: BillComparisonResponse;
}

const PaymentOptionsContainer: React.FC<Props> = (props: Props): JSX.Element => {
  const { restoreDetails, experianResponse, billComparisonResponse } = props;
  const theme = useTheme();
  const formMethods = useForm({ defaultValues, reValidateMode: 'onSubmit' });
  const { handleSubmit, control, formState, clearErrors } = formMethods;
  const { inputValue } = useContext(PaymentOptionsContext);

  const sx: Record<string, SxProps> = {
    submitButton: {
      margin: 'auto',
      background: theme.palette.dteBlue.main,
      textTransform: 'none',
      color: theme.palette.common.white,
      height: '40px',
      borderRadius: 'unset',
      '&:hover': {
        background: theme.palette.dteBlue.dark,
        boxShadow: 'unset',
      },
      '&:focus': {
        background: theme.palette.dteBlue.dark,
        color: theme.palette.common.white,
        boxShadow: 'unset',
      },
      width: '100%',
      maxWidth: '300px',
      boxShadow: 'unset',
    },
  };

  const {
    totalPastDueAmount: totalPastDueAmountString = '0',
    reconnectionFee: reconnectionFeeString = '0',
    depositAmount: depositAmountString = '0',
  } = restoreDetails;
  const totalPastDueAmount = parseFloat(totalPastDueAmountString);
  const reconnectionFee = parseFloat(reconnectionFeeString);
  const depositAmount = parseFloat(depositAmountString);

  const minimumAmountDue: number = totalPastDueAmount + reconnectionFee + depositAmount;
  const totalAmountDue: number = minimumAmountDue + billComparisonResponse.billComparison.currentCycle.billAmount;

  const paymentInfo: PaymentInfo = {
    pastDueAmount: totalPastDueAmount,
    reconnectAmount: reconnectionFee,
    securityDepositAmount: depositAmount,
    currentBillAmount: billComparisonResponse.billComparison.currentCycle.billAmount,
    minimumAmountDue,
    totalAmountDue,
  };

  const optionSelectTabletAndDesktop = <OptionSelectDesktop paymentInfo={paymentInfo} />;
  const optionSelectMobile = <OptionSelectMobile paymentInfo={paymentInfo} />;
  const responsiveOptionSelect = useResponsiveRendering(
    optionSelectMobile,
    optionSelectTabletAndDesktop,
    optionSelectTabletAndDesktop,
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSubmit = async (data: FieldValues) => {
    const formData = new URLSearchParams();

    const webUserId = localStorage.getItem('webUserId');
    const selectedPaymentOption: PaymentOption = data.PaymentOptions;
    if (selectedPaymentOption === PaymentOption.MINIMUM_AMOUNT_DUE) {
      formData.append('paymentAmount', paymentInfo.minimumAmountDue.toFixed(2));
      formData.append('paymentOption', PaymentOption.MINIMUM_AMOUNT_DUE);

      localStorage.setItem(`paymentAmount_${webUserId}`, paymentInfo.minimumAmountDue.toFixed(2));
    } else if (selectedPaymentOption === PaymentOption.TOTAL_AMOUNT_DUE) {
      formData.append('paymentAmount', paymentInfo.totalAmountDue.toFixed(2));
      formData.append('paymentOption', PaymentOption.TOTAL_AMOUNT_DUE);

      localStorage.setItem(`paymentAmount_${webUserId}`, paymentInfo.totalAmountDue.toFixed(2));
    } else {
      if (inputValue) {
        formData.append('paymentAmount', inputValue.replaceAll(',', ''));
        formData.append('paymentOption', PaymentOption.PAY_ANOTHER_AMOUNT);
      }

      localStorage.setItem(`paymentAmount_${webUserId}`, inputValue);
    }
    formData.append('minimumAmountDue', paymentInfo.minimumAmountDue.toFixed(2));
    formData.append('businessId', experianResponse.businessID.toString());
    formData.append('premisesId', restoreDetails.premise.toString());
    formData.append('cashAllowedIndicator', `${restoreDetails.cashAllowedIndicator}`);
    formData.append('cardAllowedIndicator', `${restoreDetails.cardAllowedIndicator}`);
    formData.append('checkAllowedIndicator', `${restoreDetails.checkAllowedIndicator}`);
    formData.append('installationNumber', `${restoreDetails.installation}`);
    formData.append('accounts', restoreDetails.contractAccountNo.toString());
    formData.append('reconnectDetailsUrl', window.location.href);
    formData.append('redirectUrl', window.location.hostname + reconnectConfirmationPath);

    const actionObjectMap: { [key in PaymentOption]: string } = {
      [PaymentOption.MINIMUM_AMOUNT_DUE]: 'payment min due',
      [PaymentOption.TOTAL_AMOUNT_DUE]: 'payment total due',
      [PaymentOption.PAY_ANOTHER_AMOUNT]: 'payment other amount',
    };

    const actionDetailMap: { [key in PaymentOption]: string } = {
      [PaymentOption.MINIMUM_AMOUNT_DUE]: minimumAmountDue.toString(),
      [PaymentOption.TOTAL_AMOUNT_DUE]: totalAmountDue.toString(),
      [PaymentOption.PAY_ANOTHER_AMOUNT]: inputValue,
    };

    const actionObject = actionObjectMap[selectedPaymentOption];
    const actionDetail = actionDetailMap[selectedPaymentOption];

    trackGA4Event(reconnectDetailsMakeAPaymentClickEvent(actionObject, actionDetail));

    await fetch(lambdaReconnectUrl, {
      method: 'POST',
      body: formData.toString(),
      redirect: 'follow',
      credentials: 'include',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then(() => {
        window.location.href = paymentCenterReconnectUrl;
      })
      .catch(() => {});
  };

  useEffect(() => {
    trackGA4Event(reconnectDetailsViewEvent(minimumAmountDue.toString()));
  }, [minimumAmountDue]);

  const handleKeyDownOnForm = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      const { activeElement } = document;
      if ((activeElement as HTMLInputElement)?.type !== 'button') {
        event.preventDefault();
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          // eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-shadow
          render={({ field: { onChange, value, ref, ...props } }) => (
            <FormControl variant="standard">
              <PaymentOptionsLabel />
              <RadioGroup
                {...props}
                ref={ref}
                onChange={(event: SyntheticEvent): void => {
                  clearErrors('PaymentOptions');
                  onChange(event);
                }}
                onKeyDown={handleKeyDownOnForm}
                value={value}
              >
                {responsiveOptionSelect}
              </RadioGroup>
              {formState.errors.PaymentOptions?.type === 'noOptionSelected' ? (
                <ErrorMessage errorText="Please make a selection to continue." />
              ) : (
                <></>
              )}
              {formState.errors.PaymentOptions?.type === 'inputEmptyOrZero' ? (
                <ErrorMessage errorText="Please make corrections to continue." />
              ) : (
                <></>
              )}
              <SecurityDepositNote />
              <Button data-testid="make-a-payment-button" type="submit" variant="contained" sx={sx.submitButton}>
                <Typography fontWeight={600} variant="body2">
                  Make a Payment
                </Typography>
              </Button>
            </FormControl>
          )}
          name="PaymentOptions"
          control={control}
          rules={{
            validate: {
              noOptionSelected: (value: string) => !(value === '') || 'no option is selected',
              inputEmptyOrZero: (value: string) =>
                !(value === 'pay-another-amount' && (!inputValue || Number(inputValue) === 0)) || 'input is empty',
            },
          }}
        />
      </form>
    </FormProvider>
  );
};

export default PaymentOptionsContainer;
