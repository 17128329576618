import { FC, SyntheticEvent } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, SxProps, Typography } from '@mui/material';

type Props = {
  dataTestIdPrefix?: string;
  handleTooltipClose: (event: SyntheticEvent | Event) => void;
  popoverText: string;
};

const sx: Record<string, SxProps> = {
  tooltipBubble: {
    alignItems: 'flex-start',
    display: 'flex',
    gap: '20px',
    justifyContent: 'space-between',
    maxWidth: '325px',
    textAlign: 'left',
  },
  tooltipCancelButton: {
    marginLeft: '-29px',
    padding: '10px',
    right: '-10px',
    top: '-10px',
  },
};

const DteTooltipBubble: FC<Props> = ({ dataTestIdPrefix = 'generic', handleTooltipClose, popoverText }: Props) => (
  <Box data-testid={`${dataTestIdPrefix}-tooltip-bubble`} sx={sx.tooltipBubble}>
    <Typography fontSize={16} data-testid={`${dataTestIdPrefix}-tooltip-text`} variant="body1">
      {popoverText}
    </Typography>
    <IconButton onClick={(event: SyntheticEvent): void => handleTooltipClose(event)} sx={sx.tooltipCancelButton}>
      <CloseRoundedIcon color="primary" data-testid={`${dataTestIdPrefix}-tooltip-cancel-button`} />
    </IconButton>
  </Box>
);

export default DteTooltipBubble;
