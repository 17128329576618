import { MouseEventHandler, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, SxProps, Typography, useTheme } from '@mui/material';

import { DteButton } from 'component-library/buttons/DteButton';
import HeaderDesktop from 'component-library/header/HeaderDesktop';
import HeaderMobile from 'component-library/header/HeaderMobile';
import PageNotFoundIcon from 'component-library/icons/PageNotFoundErrorIcon';
import { PageTemplate } from 'component-library/page-templates/PageTemplate';
import { useResponsiveRendering } from 'theme/breakpoints/responsiveHooks';

import { trackGA4Event } from '../../gtm/gtag';
import { useGoogleTagManager } from '../../gtm/useGoogleTagManager';
import { currentBillUrl } from '../../urls-and-paths';

import {
  lockStatusErrorPageViewCurrentBillClickEvent,
  lockStatusErrorPageViewEvent,
} from './gtmEventsLockStatusErrorPage';

export const LockStatusErrorPage = (): React.ReactElement => {
  const isGoogleTagManagerLoaded = useGoogleTagManager();
  const headerTitle = 'Service Protection';
  const mobileHeader = <HeaderMobile titleText={headerTitle} isHeaderButtonRendered={false} isInteractiveDteLogo />;
  const desktopHeader = <HeaderDesktop titleText={headerTitle} isHeaderButtonRendered={false} isInteractiveDteLogo />;
  const pageHeader = useResponsiveRendering(mobileHeader, desktopHeader, desktopHeader);

  useEffect(() => {
    if (isGoogleTagManagerLoaded) {
      trackGA4Event(lockStatusErrorPageViewEvent);
    }
  }, [isGoogleTagManagerLoaded]);

  const LockStatusErrorContent = ({ viewCurrentBillOnClickHandler }: ContentProps): React.ReactElement => {
    const theme = useTheme();

    const contentWrapperSx: SxProps = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '40px 0px',
      gap: '32px',
    };

    const titleSx: SxProps = {
      color: theme.palette.primary.main,
      fontSize: '32px',
      fontWeight: '300',
    };

    const instructionsSx: SxProps = {
      textAlign: 'center',
      color: theme.palette.secondary.main,
      fontSize: '22px',
      fontWeight: '400',
      fontStyle: 'italic',
    };

    const apologySx: SxProps = {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: '400',
      fontStyle: 'italic',
    };

    return (
      <Box sx={contentWrapperSx}>
        <PageNotFoundIcon />
        <Typography sx={titleSx} role="heading" aria-level={1}>
          System Error
        </Typography>
        <Typography sx={instructionsSx}>Please go back to Current Bill and try again.</Typography>
        <DteButton variant="primary" text="View Current Bill" onClick={viewCurrentBillOnClickHandler} />
        <Typography sx={apologySx}>We apologize for the inconvenience.</Typography>
      </Box>
    );
  };

  const viewCurrentBillOnClickHandler: MouseEventHandler<HTMLButtonElement> = () => {
    trackGA4Event(lockStatusErrorPageViewCurrentBillClickEvent);
    window.location.href = currentBillUrl;
  };

  return (
    <>
      <Helmet>
        <title>Error | DTE Energy</title>
      </Helmet>
      <PageTemplate
        header={pageHeader}
        allViewportPaddingTop="0px"
        tabletDesktopContentWidth="470px"
        dataTestId="lock-status-error-page-container"
      >
        <LockStatusErrorContent viewCurrentBillOnClickHandler={viewCurrentBillOnClickHandler} />
      </PageTemplate>
    </>
  );
};

type ContentProps = {
  viewCurrentBillOnClickHandler: MouseEventHandler<HTMLButtonElement>;
};
